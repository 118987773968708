import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { ParamsDownloadHistory, ParamsListAppHistory, ParamsOriginSegmentHistory } from "@/models/generalHistory/generalHistory";
import { getDownloadHCXService, getDownloadHistoryService, getListAccountsService, getListAppointmentHistoryService, getListPatientService, getOriginListSegmentService } from "@/services/generalHistory/generalHistory";

export function getPatientList(params: { orderByType: string; search: string, page: number; perpage: number}) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getListPatientService(params);
            if (response.success) return response.results;
            return [];
        } finally {
            dispatch(finishLoading());
        }
    };
}

export async function getAccountsList() {
    const response = await getListAccountsService();
    return response.results;
}

export function getListAppointmentHistory(params: ParamsListAppHistory) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getListAppointmentHistoryService(params);
            if (response.success) return response;
        } finally {
            dispatch(finishLoading());
        }
    };
}

export async function getOriginListSegment(params: ParamsOriginSegmentHistory) {
    const response = await getOriginListSegmentService(params);
    return response.results;
}

export function getDownloadHistory(params: ParamsDownloadHistory) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDownloadHistoryService(params);
            if (response.success) { return response.results; }
            return [];
        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getDownloadHCX(params: { cluId: number }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDownloadHCXService(params);
            if (response) { return response; }
        } finally {
            dispatch(finishLoading());
        }
    };
}



