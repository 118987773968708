import { Checkbox, SectionCard, TextField } from "@/components";
import { AdmissionNotes, Checklist, VitalSignsARR } from "@/models/surgery/patientAttention/patientAttention";
import { useEffect, useState } from "react";

const Admission = ({ data, onSubmitNotes, readOnly }: {
    data?: AdmissionNotes,
    onSubmitNotes?: (section: string, payload: AdmissionNotes) => void; 
    readOnly?: boolean;
}) => {

    const [admissionNotes, setAdmissionNotes] = useState<AdmissionNotes>({
        admissionNotes: "",
        checklist: [],
        userInfo: "",
        vitalSigns: [],
    });


    const handleChangeDynamic = (value: string | number, name: string, id: number) => {
        if (admissionNotes && onSubmitNotes) {
            const payload = {
                ...admissionNotes,
                [name]: admissionNotes[name].map((key: VitalSignsARR) => key.vitalSingId === id ? ({...key, value}) : key )
            };
            setAdmissionNotes(payload);
            onSubmitNotes("admission", payload);

        }
    };
    const handleChangeChecks = (value: string | number, name: string, id: number) => {
        if (admissionNotes && onSubmitNotes) {
            const payload = {
                ...admissionNotes,
                [name]: admissionNotes[name].map((key: Checklist) => key.id === id ? ({...key, checked: value}) : key )
            };
            setAdmissionNotes(payload);
            onSubmitNotes("admission", payload);

        }
    };
    const handleChangeDescription = (value: string | number, name: string,) => {
        if (admissionNotes && onSubmitNotes) {
            const payload = {
                ...admissionNotes,
                [name]: value
            };
            setAdmissionNotes(payload);
            onSubmitNotes("admission", payload);

        }
    };

    useEffect(() => {

        if (data) {
            setAdmissionNotes(data);
        }
    }, [data]);
    return (
        <div className="my-3 mx-4  row">
            <div className=" col-4">

                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Signos vitales</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="row g-3 align-items-center ">
                        {admissionNotes.vitalSigns && admissionNotes.vitalSigns?.length > 0 && admissionNotes.vitalSigns?.map((vital) => { 
                            return <div className="col-lg-6 d-flex align-items-center" key={vital.vitalSingId}>
                                <div className="w-100">{vital.name}</div>
                                <TextField
                                    value={vital.value}
                                    type="number"
                                    variant="plain"
                                    readOnly={readOnly}
                                    onChange={({target})=>{
                                        handleChangeDynamic(target.value, "vitalSigns", vital.vitalSingId); 
                                    }}
                                />
                            </div>;
                        })
                        } 
                    </div>

                </SectionCard>
            </div>
            <div className=" col-8">

                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Notas de admisión</h5>
                    </div>
                    <hr className="lineCards" />
                    <textarea
                        className="form-control no-resize w-100"
                        style={{ height: 100 }}
                        value={admissionNotes?.admissionNotes}
                        readOnly={readOnly} 
                        onChange={({target})=>{
                            handleChangeDescription(target.value, "admissionNotes");
                        }}
                    />
                </SectionCard>
            </div>
            <div className="col-12">

                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Lista de chequeo</h5>
                    </div>
                    <hr className="lineCards" />
                    {admissionNotes?.checklist && admissionNotes?.checklist?.length > 0 && admissionNotes?.checklist?.map((check) => {
                        return (
                            <Checkbox
                                key={check.id}
                                className="text-muted fw-normal mb-2"
                                label={check.description}
                                checked={check?.checked === 1 ? true : false}
                                readOnly={readOnly} 
                                onChange={({target})=>{
                                    handleChangeChecks(target.checked ? 1 : 0, "checklist", check.id); 
                                }}

                            />

                        );
                    })}
                </SectionCard>
            </div>


        </div>
    );
};

export default Admission;